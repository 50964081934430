@import 'reset.scss';
@import 'mixins.scss';

$primary-color: #BB2649;
$secondary-color: #000000;

@font-face {
    font-family: 'Bulevar';
    src: url(../fonts/bulevar-poster-webfont.woff2) format('woff2');
}
@font-face {
    font-family: 'N27';
    src: url(../fonts/N27-Regular.otf) format('opentype');
}
@font-face {
    font-family: 'N27';
    src: url(../fonts/N27-Bold.otf) format('opentype');
    font-weight: 800;
}

html {
    font-size: 62.5%;
    cursor: none;
}

body {
    font-size: 1.6rem;
    line-height: 1.87;
    background: $secondary-color;
    color: $primary-color;
    font-family: 'N27';
    @include media-query(tablet) {
        font-size: 2.6rem;
        line-height: 1.52;
    }
}

/* Groups */

/* Components */
@import 'components/sections.scss';
@import 'components/title.scss';
@import 'components/contact.scss';
@import 'components/projects.scss';
@import 'components/cookie.scss';

/* Groups */
@import 'groups/agencies.scss';
@import 'groups/projects.scss';

/* Objects */
@import 'objects/intro.scss';
@import 'objects/mouse.scss';
